<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12">
        <v-encounter-bar v-model="currentEncounterId" @encounterLoaded="onEncounterLoaded" @encounterUnloaded="onEncounterUnloaded"></v-encounter-bar>
      </v-col>
      <v-col cols="12" v-if="currentEncounter">
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab key="dashboard">Dashboard</v-tab>
            <v-tab key="encounterDocuments">เอกสารครั้งนี้</v-tab>
            <v-tab key="printDocuments">พิมพ์เอกสาร</v-tab>
            <v-tab key="scanDocuments">สแกนเอกสาร</v-tab>
            <v-tab key="medicalRecords">เวชระเบียน</v-tab>
            <v-tab key="radiology">รังสีวินิจฉัย</v-tab>
            <v-tab key="dispensings">จ่ายของ</v-tab>
            <v-tab key="dispensingsTemporary">จ่ายยา</v-tab>
            <v-tab key="temporaryStock">ตะกร้ายา</v-tab>
            <v-tab key="transactions">ค่าใช้จ่าย</v-tab>
            <v-tab key="vouchers">Vouchers</v-tab>
            <v-tab key="appointments">นัดหมาย</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item key="dashboard">
              <v-nurse-dashboard :encounter="currentEncounter" @encounterUnloaded="reset"></v-nurse-dashboard>
            </v-tab-item>
            <v-tab-item key="encounterDocuments">
              <v-document-editor :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId" folder="default" templateCode="MRC-FM-024" find-latest read-only showPrintButton></v-document-editor>
            </v-tab-item>
            <v-tab-item key="printDocuments">
              <v-document-print-list :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId" :show-ipd="false"></v-document-print-list>
            </v-tab-item>
            <v-tab-item key="scanDocuments">
              <v-document-scan :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId" refreshable></v-document-scan>
            </v-tab-item>
            <v-tab-item key="medicalRecords">
              <v-document-list :hn="currentEncounter.hn" folder="default"></v-document-list>
            </v-tab-item>
            <v-tab-item key="radiology">
              <v-radiology-list :hn="currentEncounter.hn"></v-radiology-list>
            </v-tab-item>
            <v-tab-item key="dispensings">
              <v-encounter-dispensings :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId" :transactions-table="$refs.transactionsTable"></v-encounter-dispensings>
            </v-tab-item>
            <v-tab-item key="dispensingsTemporary">
              <v-encounter-temporary-dispensings :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId" :transactions-table="$refs.transactionsTable"></v-encounter-temporary-dispensings>
            </v-tab-item>
            <v-tab-item key="temporaryStock">
              <v-encounter-temporary-stock :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId"></v-encounter-temporary-stock>
            </v-tab-item>
            <v-tab-item key="transactions">
              <v-encounter-transaction ref="transactionsTable"
                :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId"
                :products-filter="{productType: ['procedure','service','laboratory','radiology','package']}"
                :read-only="!!currentEncounter.dischargeDateTime"
              ></v-encounter-transaction>
            </v-tab-item>
            <v-tab-item key="vouchers">
              <v-encounter-voucher :encounterId="currentEncounter.encounterId"></v-encounter-voucher>
            </v-tab-item>
            <v-tab-item key="appointments">
              <v-appointment :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId" :clinicCode="currentEncounter.clinicCode" :doctorCode="currentEncounter.doctorCode"></v-appointment>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
      <v-col cols="12" v-else>
        <v-tabs-group key="overall">
          <template v-slot:tabs>
            <v-tab key="registered">รายการลงทะเบียนวันนี้</v-tab>
            <v-tab key="appointed">รายการนัดหมาย</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item key="registered">
              <v-model-table model-name="Encounters" model-title="รายการลงทะเบียนวันนี้"
                :model-api="['models','Registration','Encounters']" model-key="encounterId"
                :model-api-search="opdEncounterQuery"
                :headers="headers"
                :insertable="false"
              >
                <template v-slot:toolBarItem>
                  <v-master-field v-model="currentClinic" :custom-api="['models','Master','Clinics','query']"
                    custom-api-text="clinicName" custom-api-value="clinicCode"
                    customApiEagerLoad
                    class="pr-1" clearable
                    label="คลินิก" 
                    single-line hide-details
                    show-code></v-master-field>
                  <v-master-field v-model="currentDoctorCode" :custom-api="['models','Master','Doctors','query']"
                    custom-api-text="nameTH" custom-api-value="doctorCode"
                    customApiEagerLoad
                    class="pr-1" clearable
                    label="แพทย์" 
                    single-line hide-details
                    show-code></v-master-field>
                </template>
                <template v-slot:toolBarSearch>
                  <v-text-field
                    class="pr-2"
                    v-model="currentAppointmentFilter"
                    label="Search"
                    append-icon="search"
                    single-line
                    hide-details
                  ></v-text-field>
                </template>
                <template v-slot:item.encounterId="props">
                  <v-icon v-if="props.item.dischargeDateTime" color="indigo">mdi-exit-run</v-icon>
                  <v-icon v-else-if="props.item.fromAppointmentId" color="green darken-3">mdi-calendar-check</v-icon>
                  <v-icon v-else color="red">mdi-calendar-remove</v-icon>
                  <label class="font-weight-bold">{{ props.item.encounterId.slice(-3) }}</label>
                </template>
                <template v-slot:item.patient.name_th="props">
                  <v-label-name :name-data="props.item.patient.name_th"></v-label-name>
                </template>
                <template v-slot:item.admitDateTime="props">
                  <v-label-datetime :date-time="props.item.admitDateTime" short-date-time></v-label-datetime>
                </template>
                <template v-slot:item.locationSubunitCode="props">
                  <v-label-items
                    :items="props.item.location.subunits"
                    item-key="subunitCode"
                    item-value="subunitName"
                    :value="props.item.locationSubunitCode"
                  ></v-label-items>
                </template>
                <template v-slot:item.action="props">
                  <v-btn color="primary" @click="currentEncounterId=props.item.encounterId">เปิด</v-btn>
                </template>
              </v-model-table>
            </v-tab-item>
            <v-tab-item key="appointed">
              <v-model-table model-name="Appointments" model-title="รายการนัดหมาย"
                :model-api="['models','Appointment','Appointments']"
                :model-api-search="appointmentQuery"
                api-with="patient"
                :headers="appointmentHeaders" :insertable="false" expandable>
                <template v-slot:toolBarItem>
                  <v-date-field
                    v-model="currentAppointmentDate"
                    label="วันที่รับบริการ"
                    class="px-1"
                    single-line
                    hide-details></v-date-field>
                  <v-master-field v-model="currentClinic" :custom-api="['models','Master','Clinics','query']"
                    custom-api-text="clinicName" custom-api-value="clinicCode"
                    customApiEagerLoad
                    class="pr-1" clearable
                    label="คลินิก" 
                    single-line hide-details
                    show-code></v-master-field>
                  <v-master-field v-model="currentDoctorCode" :custom-api="['models','Master','Doctors','query']"
                    custom-api-text="nameTH" custom-api-value="doctorCode"
                    customApiEagerLoad
                    class="pr-1" clearable
                    label="แพทย์" 
                    single-line hide-details
                    show-code></v-master-field>
                </template>
                <template v-slot:toolBarSearch>
                  <v-text-field
                    class="pr-2"
                    v-model="currentAppointmentFilter"
                    label="Search"
                    append-icon="search"
                    single-line
                    hide-details
                  ></v-text-field>
                </template>
                <template v-slot:item.patient.name_th="props">
                  <v-label-name :name-data="props.item.patient.name_th"></v-label-name>
                </template>
                <template v-slot:item.appointmentDateTime="props">
                  <v-label-datetime :date-time="props.item.appointmentDateTime" short-date-time></v-label-datetime>
                </template>
                <template v-slot:item.appointmentType="props">
                  <v-label-master group-key="appointmentType" :item-code="props.item.appointmentType"></v-label-master>
                </template>
                <template v-slot:item.appointmentActivity="props">
                  <v-label-master group-key="appointmentActivity" :item-code="props.item.appointmentActivity"></v-label-master>
                </template>
                <template v-slot:item.showStatus="props">
                  <v-icon
                    color="green darken-3"
                    v-if="props.item.to_encounter && props.item.to_encounter.length>0"
                  >mdi-check-circle-outline</v-icon>
                </template>
                <template v-slot:expanded-item="{ headers,item,actions }">
                  <td :colspan="headers.length" class="py-0">
                    <v-row>
                      <v-col cols="12" sm="4" md="2" class="font-weight-bold">คำแนะนำ</v-col>
                      <v-col cols="12" sm="8" md="10" style="white-space: pre-line;">{{ item.suggestion || "-" }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="4" md="2" class="font-weight-bold">บันทึกภายใน</v-col>
                      <v-col cols="12" sm="8" md="10" style="white-space: pre-line;">{{ item.note || "-" }}</v-col>
                    </v-row>
                    <v-row v-if="item.from_encounter">
                      <v-col cols="12" class="font-weight-bold">ประวัติการรักษาครั้งก่อนหน้า</v-col>
                      <v-col cols="12">
                        <v-document-list
                          :hn="item.from_encounter.hn"
                          :encounterId="item.from_encounter.encounterId"
                          folder="default"
                          flat
                        ></v-document-list>
                      </v-col>
                    </v-row>
                  </td>
                </template>
              </v-model-table>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import encounterLoader from "@/components/mixins/encounterLoader";
import helperDateTime from "tantee-common/lib/helperDateTime";
import { isUndefined, isNil } from "lodash";

export default {
  mixins: [encounterLoader],
  data: () => ({
    currentEncounterId: undefined,
    currentAppointmentDate: undefined,
    currentAppointmentFilter: undefined,
    currentClinic: undefined,
    currentDoctorCode: undefined,
    headers: [
      {
        text: "เลขรับบริการ",
        value: "encounterId",
        class: "body-2",
      },
      {
        text: "HN",
        value: "hn",
        class: "body-2",
      },
      {
        text: "ชื่อ-นามสกุล",
        value: "patient.name_th",
        class: "body-2",
      },
      {
        text: "เวลาเข้าตรวจ",
        value: "admitDateTime",
        class: "body-2",
      },
      {
        text: "สถานที่",
        value: "location.locationName",
        class: "body-2",
        width: "200px",
      },
      {
        text: "คลินิก",
        value: "clinic.clinicName",
        class: "body-2",
      },
      {
        text: "แพทย์",
        value: "doctor.nameTH",
        class: "body-2",
      },
      {
        text: "ห้องตรวจ",
        value: "locationSubunitCode",
        class: "body-2",
      },
      {
        text: "Action",
        value: "action",
        align: "center",
        width: "200px",
        sortable: false,
        class: "body-2",
      },
    ],
    appointmentHeaders: [
      {
        text: "",
        value: "showStatus",
        class: "body-2",
        width: "50px",
      },
      {
        text: "เวลานัดหมาย",
        value: "appointmentDateTime",
        class: "body-2",
      },
      {
        text: "HN",
        value: "hn",
        class: "body-2",
      },
      {
        text: "ชื่อ-นามสกุล",
        value: "patient.name_th",
        class: "body-2",
      },
      {
        text: "คลินิก",
        value: "clinic.clinicName",
        class: "body-2",
      },
      {
        text: "แพทย์",
        value: "doctor.nameTH",
        class: "body-2",
      },
      {
        text: "ชนิดการนัด",
        value: "appointmentType",
        class: "body-2",
      },
      {
        text: "กิจกรรม",
        value: "appointmentActivity",
        class: "body-2",
      },
    ],
  }),
  computed: {
    opdEncounterQuery() {
      let location = this.$store.getters.getConfig("location");
      let result = {
        encounterType: "AMB",
        "whereDate#admitDateTime": helperDateTime.today().format("YYYY-MM-DD"),
        locationCode:
          location && location.locationCode ? location.locationCode : undefined,
      };

      if (!isNil(this.currentAppointmentFilter)) {
        result["where#hn#like"] = this.currentAppointmentFilter + "%";
      }
      if (this.currentClinic) result['clinicCode'] = this.currentClinic
      if (this.currentDoctorCode) result['doctorCode'] = this.currentDoctorCode
      
      return result;
    },
    appointmentQuery() {
      let result = {
        "whereDate#appointmentDateTime": this.currentAppointmentDate,
      };
      if (!isNil(this.$config("location").locationCode)) {
        result["clinic$locationCode"] = this.$config("location").locationCode;
      }
      if (!isNil(this.currentAppointmentFilter)) {
        result["where#hn#like"] = this.currentAppointmentFilter + "%";
      }
      if (this.currentClinic) result['clinicCode'] = this.currentClinic
      if (this.currentDoctorCode) result['doctorCode'] = this.currentDoctorCode

      return result;
    },
  },
  methods: {
    reset() {
      this.currentEncounterId = undefined;
      this.currentEncounter = undefined;
    },
  },
  beforeMount() {
    this.currentAppointmentDate = helperDateTime.today().format("YYYY-MM-DD");
  },
};
</script>

<style lang="css" scoped>
</style>
